<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 점검유형 -->
          <c-select
            :comboItems="checkTypeCdItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="chngAttCd"
            label="점검유형"
            v-model="searchParam.safCheckTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          <!-- 진행상태 -->
          <c-select
            :comboItems="checkStepCdItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="chngAttCd"
            label="진행상태"
            v-model="searchParam.safChkStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="유해위험기계기구 점검 목록"
      tableId="equipmentInsp"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      selection="multiple"
      rowKey="equipmentCheckSeq"
      @linkClick="linkClick"
      @getSelected="getSelected"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <c-btn label="계획등록" :editable="editable" icon="add" @btnClicked="onItemClick('A')" />
            <c-btn label="결과등록" :editable="editable" icon="add" @btnClicked="onItemClick('B')" />
            <c-btn label="무계획결과등록" :editable="editable" icon="add" @btnClicked="onItemClick('C')" />
            <c-btn label="결과요약본" icon="info" @btnClicked="onItemClick('E')" />
            <c-btn label="삭제" :editable="editable" icon="delete_forever" @btnClicked="onItemClick('D')" />
            <c-btn label="검색" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
export default {
  name: "equipment-inspection",
  data() {
    return {
      grid: {
        merge: [{ index: 0, colName: "plantName" }],
        columns: [
          {
            name: "plantName",
            field: "plantName",
            label: "사업장",
            align: "center",
            sortable: true,
          },
          {
            name: "deptNm",
            field: "deptNm",
            label: "부서",
            align: "center",
            sortable: true,
          },
          {
            name: "equipmentCd",
            field: "equipmentCd",
            label: "설비명(관리번호)",
            align: "center",
            sortable: true,
            type: "link",
          },
          {
            name: "safChkStepNm",
            field: "safChkStepNm",
            label: "진행상태",
            align: "center",
            sortable: true,
          },
          {
            name: "safCheckTypeNm",
            field: "safCheckTypeNm",
            label: "점검유형",
            align: "center",
            sortable: true,
          },
          {
            name: "befInspYear",
            field: "befInspYear",
            label: "이전 점검일",
            align: "center",
            sortable: true,
          },
          {
            name: "inspCycle",
            field: "inspCycle",
            label: "점검주기",
            align: "center",
            sortable: true,
          },
          {
            name: "nextInspMon",
            field: "nextInspMon",
            label: "차기검사예정월",
            align: "center",
            sortable: true,
          },
          {
            name: "relatedLaws",
            field: "relatedLaws",
            label: "관련법규",
            align: "center",
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        safCheckTypeCd: null,
        safChkStepCd: null,
      },
      editable: true,
      checkTypeCdItems: [
        { code: "1", codeName: "자체(일상)점검" },
        { code: "2", codeName: "법정(정기)점검" },
      ], // 점검종류
      checkStepCdItems: [
        { code: "1", codeName: "계획수립" },
        { code: "2", codeName: "점검중" },
        { code: "3", codeName: "점검완료" },
      ], // 점검종류
      searchUrl: "",
      popupOptions: {
        isFull: true,
        target: null,
        title: "",
        visible: false,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.grid.data = [
        {
          equipmentCheckSeq: 1,
          plantName: "사업장1",
          deptNm: "안전환경팀",
          equipmentCd: "F3-G938",
          safCheckTypeNm: "자체(일상)점검",
          safChkStepCd: 'EI02',
          safChkStepNm: '점검중',
          befInspYear: "2021-01-22",
          inspCycle: "6개월",
          nextInspMon: "2021-07-22",
          relatedLaws: "산업안전보건법",
        },
        {
          equipmentCheckSeq: 2,
          plantName: "사업장2",
          deptNm: "개발자팀",
          safChkStepCd: 'EI02',
          safChkStepNm: '점검중',
          equipmentCd: "F3-PV-15",
          safCheckTypeNm: "법정(정기)점검",
          befInspYear: "2020-02-20",
          inspCycle: "1년",
          nextInspMon: "2021-02-20",
          relatedLaws: "산업안전보건법",
        },
      ];
    },
    /* eslint-disable no-unused-vars */ 
    linkClick(row, col, index) {
      this.popupOptions.title = "유해위험기계기구 점검결과 상세"; // 유해위험기계기구점검계획 상세
      this.popupOptions.param = {
        row: row,
        title: '유해위험기계기구 점검결과',
        multiple: false,
        noPlan: 'N',
      };
      this.popupOptions.target = () =>
        import(`${"./hazardEquipmentResult.vue"}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    onItemClick(data) {
      if (data === 'B' || data === 'D'  || data === 'E') {
        let selectData = this.$refs['table'].selected;
        if (selectData && selectData.length === 0) {
          window.getApp.$emit('ALERT', {
            title: '안내', // '안내',
            message: '하나 이상 선택하세요.', 
            type: 'warning',
          });
          return;
        }
      }
      let title = '';
      let param = null;

      if (data === 'A') {
        param = {
          title: "유해위험기계기구 점검계획",
          hazardMachineYn: 'Y',
        };
        title = '유해위험기계기구 점검계획 상세';
        this.popupOptions.target = () =>
        import(`${"./hazardEquipmentPlan.vue"}`);
      }
      if (data === 'B') {
        param = {
          title: "유해위험기계기구점검결과",
          noPlan: 'N',
          multiple: true,
          hazardMachineYn: 'Y',
        };
        title = '유해위험기계기구 점검결과 상세';
        this.popupOptions.target = () =>
        import(`${"./hazardEquipmentResult.vue"}`);
      }
      if (data === 'C') {
        param = {
          title: "무계획결과",
          noPlan: 'Y',
          multiple: true,
          hazardMachineYn: 'Y',
        };
        title = '무계획결과 상세';
        this.popupOptions.target = () =>
        import(`${"./hazardEquipmentResult.vue"}`);
      }
      if (data === 'D') {
        alert('삭제했습니다.')
      }
      if (data === 'E') {
        param = {
          hazardMachineYn: 'Y',
        };
        title = '결과요약본';
        this.popupOptions.target = () =>
        import(`${"./hazardEquipmentComplete.vue"}`);
      }
      this.popupOptions.title = title; // 유해위험기계기구점검계획 상세
      this.popupOptions.param = param;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    btnCancel() {
      this.isResult = false;
    },
    getSelected(data) {
    },
  },
};
</script>
